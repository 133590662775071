import { Dimensions, View } from "react-native";
import {
  ChatUserName,
  MessageContainerStyle,
} from "../../styles/UserChatStyles";
import ContactRequestForm from "./ContactRequestForm";
import RegistrationForm from "./RegistrationForm";
import QuestionForm from "./QuestionForm";
import UserMessage from "./UserMessage";
import Avatar from "../../assets/user.svg";
import MainStyles from "../../styles/MainChatStyles";
import CustomText from "./CustomText";
import { useGlobalState } from "../GlobalStateProvider";

const UserFormMessage = ({
  index,
  msg,
  style,
  onSubmit,
  isCompleted,
  onSendingAnswer,
}) => {
  const viewHeight = Dimensions.get("window").height;
  const bubbleCornerRadius = viewHeight / 2;
  const cornerRadius = viewHeight > 60 ? 20 : bubbleCornerRadius;
  const fontColor = style.font_color;
  const { who, messageType, message} = msg;

  const style1 = {
    background: style.candidate_bg_color,
    borderTopLeftRadius: cornerRadius,
    borderBottomLeftRadius: cornerRadius,
    borderBottomRightRadius: cornerRadius,
  };
  if (isCompleted) {
    return (
      <View>
        <View style={MainStyles.messageContainer}>
          <View style={{ paddingTop: 15, flex: 1 }}>
            <CustomText style={ChatUserName}>{who}</CustomText>
            <View>
              <View style={[MessageContainerStyle, style1]}>
                {messageType === "contact" && (
                  <ContactRequestForm
                    color={fontColor}
                    onSubmitForm={onSubmit}
                    form={message}
                  />
                )}
                {messageType === "registration" && (
                  <RegistrationForm
                    color={fontColor}
                    onSubmitForm={onSubmit}
                    form={message}
                  />
                )}
                {messageType === "open" && (
                  <QuestionForm
                  index={index}
                    style={style}
                    color={fontColor}
                    onSubmitForm={onSendingAnswer}
                    form={message}
                  />
                )}
                {messageType === "normal" && (
                  <UserMessage color={fontColor} msg={message} index={index}/>
                )}
              </View>
            </View>
          </View>
          <View
            style={[
              MainStyles.profileContainer,
              { backgroundColor: style1.background },
            ]}
          >
            <img style={MainStyles.image} src={Avatar}></img>
          </View>
        </View>
      </View>
    );
  }
};

export default UserFormMessage;
