import React, { useEffect } from "react";
import { View, SafeAreaView } from "react-native";
import { BrowserRouter } from "react-router-dom";
import MainChat from "./components/MainChat";
import { GlobalStateProvider } from "./components/GlobalStateProvider";


const MainFile = () => {
  useEffect(() => {
    document.title = "Welcome to Vira.AI";
  }, []);
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ flex: 1, backgroundColor: "rgb(30, 33, 39)" }}>
        <BrowserRouter>
        <GlobalStateProvider>
          <MainChat />
          </GlobalStateProvider>
        </BrowserRouter>
      </View>
    </SafeAreaView>
  );
};

export default MainFile;
