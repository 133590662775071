import { Dimensions } from "react-native";

const windowSize = () => {
  const viewWidth = Dimensions.get("window").width;

  return viewWidth;
};


export const MessageContainerStyle = {
  padding: 20,
  minWidth: '35vw',
  width: windowSize()< 400 ? "50vw" : "35vw",
  alignSelf: "flex-start",
};

export const MessageTextStyle = {
  width: "100%",
};
export const BotTypograpy = {
  color: "#fff",
  fontFamily: "Inter",
  fontSize: "0.9rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: 24,
};
export const ChatBotName = {
  color: "rgb(132, 138, 152)",
  textAlign: "start",
  fontSize: "0.7rem",
};

const BotStyles = {
  botText: {
    minHeight: 25,
    overflow: "hidden",
  },
};
export default BotStyles;
