
import { Dimensions } from "react-native";

const windowSize = () => {
  const viewWidth = Dimensions.get("window").width;

  return viewWidth;
};

export const MessageContainerStyle = {
  padding: 20,
  minWidth: "35vw",
  width: windowSize()< 400 ? "50vw" : "35vw",
  alignSelf: "flex-end",
};

export const TextAreaStyle = () => ({
  appearance: "none",
  backgroundColor: "rgba(0, 0, 0, 0)",
  borderRadius: 0,
  border: "0px solid black",
  boxSizing: "border-box",
  fontFamily: "Inter",
  fontSize: "0.9rem",
  fontStyle: "normal",
  fontWeight: 400,
  margin: 0,
  paddingRight: 5,
  resize: "none",
  outlineStyle: "none",
});

export const MessageTextStyle = {
  padding: 24,
};

export const ChatUserName = {
  color: "rgb(132, 138, 152)",
  textAlign: "end",
  fontSize: "0.7rem",
};

export const ToolTipText = (fontColor) => ({
  fontSize: 12,
  color: fontColor,
  fontWeight: 400,
  paddingBottom: 5,
  borderBottom: "1px solid",
  borderColor: fontColor,
});

export const buttonStyle = {
  submitButton: {
    backgroundColor: "#5dbea3",
    paddingVertical: 5,
    borderRadius: 5,
    marginTop: 10,
    color: "white",
    alignItems: "center",
    width: 80,
    shadowOpacity: 0.3, // Shadow opacity
    shadowRadius: 4, // Shadow radius
    elevation: 5, // Android shadow elevation
  },
  submittedButton: {
    backgroundColor: "lightgray",
    paddingVertical: 5,
    borderRadius: 5,
    color: "white",
    marginTop: 10,
    alignItems: "center",
    width: 80,
    shadowOpacity: 0.3, // Shadow opacity
    shadowRadius: 4, // Shadow radius
    elevation: 5, // Android shadow elevation
  },
  uploadButton: {
    display: "flex",
    paddingHorizontal: 25,
    paddingVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    borderRadius: 40,
    backgroundColor: "rgba(255, 255, 255, 0.10)",
  },

  horizontalRow: {
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    gap: 15,
  },
  redAsterisk: {
    color: "#f14242",
    fontSize: 18,
    marginLeft: 3,
  },
};

export const Styles = {
  errorText: {
    color: "#f14242",
    fontFamily: "Inter",
    fontSize: "0.7rem",
    fontStyle: "normal",
    fontWeight: 300,
  },
  labelText: {
    fontSize: 10,
    paddingLeft: 2,
    fontWeight: 400,
    fontFamily: "Inter",
    fontSize: "0.7rem",
    fontStyle: "normal",
    fontWeight: 400,
  },
  inputText: {
    fontFamily: "Inter",
    fontSize: "0.9rem",
    fontStyle: "normal",
    fontWeight: 400,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "rgba(255, 255, 255, 0.10)",
    justifyContent: "space-between",
    backgroundColor: "rgba(255, 255, 255, 0.10)",
    padding: 8,
  },
  submittingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  submitButtonContainer: {
    alignItems: "flex-start",
    gap: 24,
    display: "flex",
  },
  image: {
    display: "flex",
    width: 48,
    height: 48,
    justifyContent: "end",
    alignItems: "center",
    gap: 0.62,
    borderRadius: 12,
  },
};
