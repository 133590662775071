import React from "react";
import Styles from "../../styles/MainChatStyles";
import { Text, View } from "react-native";
import CustomText from "./CustomText";

function Header({ styles, company, position }) {
  return (
    <View
      style={[
        Styles.headerContainer,
        { backgroundColor: styles.header_bg_color },
      ]}
    >
      <View style={Styles.logoContainer}>
        <img style={Styles.logo} src={styles.logo_url}></img>
      </View>
      {position ? (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center", // Fix: use alignItems instead of justifyItems
            margin: "auto",
            paddingVertical: 10,
          }}
        >
          <CustomText style={Styles.headerPosition}>{position}</CustomText>
        </View>
      ) : null}
    </View>
  );
}

export default Header;
