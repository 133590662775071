import { Dimensions } from "react-native";

const windowSize = () => {
  const viewWidth = Dimensions.get("window").width;

  return viewWidth;
};

const Styles = {
  container: {
    display: "flex",
    backgroundColor: "rgb(30, 33, 39)",
  },
  messageContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
  powerdByContainer: {
    height: 20,
    alignContent: "flex-end",
    justifyContent: "flex-end",
    display: "inline-flex",
    position: "fixed",
    top: 1,
    marginVertical: 20,
    right: 20,
  },
  poweredBy: {
    display: "inline-flex",
    gap: 15,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: windowSize() < 400 ? "95%" : "65%",
    height: "100%",
    margin: "auto",
  },
  customFont: {
    fontFamily: "Inter",
    fontSize: "0.9rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 24,
    color: "#fff",
  },
  profileContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 45,
    height: 45,
    padding: 5,
    overflow: "hidden",
    borderRadius: 10,
    backgroundColor:'rgb(53, 56, 63)',
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
    objectFit: "contain",
  },
  logo: {
    position: "absolute",
    height: "100%",
    width:'100%',
    alignSelf: "center",
    objectFit:'contain',
  },
  logoContainer: {
    width: "70%",
    position: "relative",
    margin: "auto",
    padding: 50,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  headerPosition: {
    marginTop:5,
    fontSize: "1.5rem",
    color: "white",
    textAlign: "center",
    fontWeight: 600,
    marginBottom: 0,
  
  },
  message: {
    margin: 10,
  },
  input: {
    height: 50,
    borderRadius: 20,
    backgroundColor: "#f7f7f7",
    alignSelf: "center",
  },
  inputText: {
    paddingBottom: 10,
    outlineStyle: "none",
  },
  sendButton: {
    backgroundColor: "#007aff",
    borderRadius: 20,
    height: 50,
    width: 100,
    alignSelf: "center",
    margin: 10,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
  },
  modalContent: {
    backgroundColor: "white",
    padding: 10,
    borderRadius: 10,
    alignItems: "center",
  },
  closeButton: {
    marginTop: 10,
    width: 35,
    height: 35,
    alignItems: "center",
    justifyContent: "center",
  },
  closeButtonText: {
    color: "blue",
    fontSize: 15, // Adjust the font size as needed
  },
  timeSection: {
    textAlign: "end",
    fontSize: '0.7rem',
    fontFamily: "Inter",
    fontStyle: "normal",
    color:"#848A98",
    fontWeight: 400,
  },
  progressBarContainer: {
    width: "100%",
    justifyContent: "center",
    position: "sticky",
    top: 180,
    zIndex: 1,
  },
  chatContainer: {
    width: "100%",
    flex: "85%",
    overflowY: "auto",
    paddingTop: 20,
  },
  scrollViewContent: {
    flex: "80%",
    gap: 3, 
    marginBottom:60,
  },
  headerContent: {
    width: "100%",
    paddingVertical: 20,
    justifyContent: "center",
    alignItems: "center",
    position: "sticky",
    height: 180,
  },
  headerContainer: {
    top: 0,
    width: "100%",
    display: "flex",
    alignContent: "center",
    paddingVertical: 20,
  },
  footerContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    height: "15%",
    width: "100%" /* Optional: Set the width of the footer as needed */,
    /* Optional: Add a background color to the footer */
    padding: 10,
  },
  line: {
    height: 1,
    width: "100%",
    backgroundColor: "gray", // Choose your line color
    margin: "auto",
    marginVertical: 20,
  },
};

export default Styles;

export const LoadingStyles = {
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  innerContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: 200,
    height: 200,
  
  },
  text: {
    fontSize: 18,
    color: "#fff",
   
  },
};
