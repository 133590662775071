import { Text, TextInput, TouchableOpacity, View } from "react-native";
import { Styles, buttonStyle } from "../../styles/UserChatStyles";
import { useEffect, useState } from "react";

import axios from "axios";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import config from "../../config";
import CustomInput from "./CustomInput";
import CustomText from "./CustomText";

const ContactRequestForm = ({ form, color, onSubmitForm }) => {
  const fontColor = color;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    // Add more fields as needed
  });
  const initialValues = {
    ...formData,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    company: Yup.string().required("Company is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  useEffect(() => {
    // Assuming `form.fields` is an array
    const fieldsData = form.fields.reduce((acc, field) => {
      const { name, value } = field;
      acc[name] = value || ""; // If `value` is null, set an empty string
      return acc;
    }, {});
    // Update the state with the fields data
    setFormData((prevData) => ({ ...prevData, ...fieldsData }));
  }, [form.fields]);

  const submitContactRequest = async (values) => {
    try {
      setSubmitting(true);
      const headers = {
        "Content-Type": "application/json",
        ...(config.apikey ? { "Vira-AI-API-Key": config.apikey } : {}),
        Accept: "application/json",
      };

      // Request body
      const requestBody = {
        ...values,
      };
      const apiUrl = config.api + form.target;
      const response = await axios.post(apiUrl, requestBody, { headers });
      const data = await response.data;

      if (response && response.status === 200) {
        const res = response.data;
        if (res.success) {
          onSubmitForm(true);
          setIsSubmitted(true);
        }
      }
    } catch (error) {
      if (error.response.data.errors) {
        setIsError(true);
        setError(error.response.data.message);
      }
    }
    setSubmitting(false);
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={submitContactRequest}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
        }) => (
          <View style={{ display: "flex", gap: 24 }}>
            <View
              style={{
                display: "flex",
                gap: 8,
              }}
            >
              <CustomText style={[Styles.labelText, { color: fontColor }]}>
                Name <Text style={buttonStyle.redAsterisk}>*</Text>
              </CustomText>
              <CustomInput
                onChangeText={handleChange("name")}
                onBlur={handleBlur("name")}
                value={values.name}
                editable={!isSubmitted}
                placeholder="Name"
                placeholderTextColor={fontColor}
                style={{
                  outlineStyle: "none",
                  color: fontColor,
                }}
              />
              {errors.name && touched.name && (
                <Text style={Styles.errorText}>{errors.name}</Text>
              )}
              <CustomText style={[Styles.labelText, { color: fontColor }]}>
                Email <Text style={buttonStyle.redAsterisk}>*</Text>
              </CustomText>

              <CustomInput
                onChangeText={handleChange("email")}
                onBlur={handleBlur("email")}
                value={values.email}
                required
                editable={!isSubmitted}
                placeholder="Email"
                placeholderTextColor={fontColor}
                style={{
                  outlineStyle: "none",
                  color: fontColor,
                }}
              />
              {errors.email && touched.email && (
                <Text style={Styles.errorText}>{errors.email}</Text>
              )}
              <CustomText style={[Styles.labelText, { color: fontColor }]}>
                Company <Text style={buttonStyle.redAsterisk}>*</Text>
              </CustomText>
              <CustomInput
                onChangeText={handleChange("company")}
                onBlur={handleBlur("company")}
                value={values.company}
                required
                editable={!isSubmitted}
                placeholder="Company"
                placeholderTextColor={fontColor}
                style={{
                  outlineStyle: "none",
                  color: fontColor,
                }}
              />
              {errors.company && touched.company && (
                <Text style={Styles.errorText}>{errors.company}</Text>
              )}
             
            
            </View>

            <View
                style={Styles.submitButtonContainer}
              >
                <TouchableOpacity
                  style={buttonStyle.uploadButton}
                  onPress={handleSubmit}
                  disabled={isSubmitted}
                >
                  {isSubmitted ? (
                    <CustomText>Submitted</CustomText>
                  ) : (
                    <CustomText>Submit</CustomText>
                  )}
                </TouchableOpacity>
              </View>
              {submitting && (
                <View style={Styles.submittingContainer}>
                  <CustomText style={{ fontSize: 13 }}>Sending...</CustomText>
                </View>
              )}
          </View>
        )}
      </Formik>
    </>
  );
};

export default ContactRequestForm;
