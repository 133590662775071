import { Dimensions, View } from "react-native";
import BotStyles, {
  MessageContainerStyle,
  MessageTextStyle,
  ChatBotName,
} from "../../styles/BotChatStyles";
import { useEffect, useState } from "react";
import TypewriterEffect from "../TypeWriter";
import Styles from "../../styles/MainChatStyles";
import CustomText from "./CustomText";

const BotMessage = ({
  msg,
  style,
  onTypewriterComplete,
  isCompleted,
  index,
}) => {
  const viewHeight = Dimensions.get("window").height;
  const bubbleCornerRadius = viewHeight / 2;
  const cornerRadius = viewHeight > 60 ? 10 : bubbleCornerRadius;
  const fontColor = style.font_color;
  const [isTyping, setIsTyping] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [formattedMessage, setFormattedMessage] = useState("");
  const { who, message } = msg;
  const [hasLink, setHasLink] = useState(false);
  const [link, setLink] = useState("");
  const [msgWithLink, setMsgWithLink] = useState("");

  const style1 = {
    background: style.bot_bg_color,
    profile_bg : style.profile_bg_color,
    borderTopRightRadius: cornerRadius,
    borderBottomRightRadius: cornerRadius,
    borderBottomLeftRadius: cornerRadius,
  };
  useEffect(() => {
    if (!isCompleted) {
      setIsTyping(true);
    }
  }, []);
  useEffect(() => {
    renderMessage();
  }, []);

  function convertLinksToHTML(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const generatedLinks = [];
    const textWithLink = text.replace(urlRegex, (url) => {
      return `<a style="color: #aaccff;" href="${url}" target="_blank">${url}</a>`;
    });

    const textWithLinks = text.replace(urlRegex, (url) => {
      const link = `<a style="color: #aaccff;" href="${url}" target="_blank">${url}</a>`;
      generatedLinks.push(link);
      return `{{${generatedLinks.length - 1}}}`; // Use a placeholder in the original text
    });
    setMsgWithLink(textWithLink);
    const textWithoutLinks = textWithLinks.replace(/\{\{\d+\}\}/g, "");
    return {
      textWithoutLinks,
    };
  }
  function containsLink(text) {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    // Check if the text contains a link
    return urlRegex.test(text);
  }
  const renderMessage = () => {
    // check if text has break to start new line in message
    if (message && message.includes("<br/>")) {
      const messageArray = message.split("<br/>");
      const formattedMsg = messageArray
        .map((substring, index) => `${index > 0 ? "\n" : ""}${substring}`)
        .join("");
      setFormattedMessage(formattedMsg);
    }
    //if message has links
    else if (containsLink(message)) {
      setHasLink(true);
      const { textWithoutLinks } = convertLinksToHTML(message);
      setFormattedMessage(textWithoutLinks);
    } else {
      setFormattedMessage(message);
    }
  };

  useEffect(() => {
    if (isDone) {
      onTypewriterComplete(index);
    }
  }, [isDone]);

  const LinkMessage = () => {
    return (
      <div
        style={{
          color: fontColor,
          fontFamily: "Inter",
          fontSize: "0.9rem",
          fontStyle: "normal",
          fontWeight: 400,
          overflow: "hidden",
        }}
        dangerouslySetInnerHTML={{ __html: msgWithLink }}
      />
    );
  };
  return (
    <View style={Styles.messageContainer}>
      <View style={[Styles.profileContainer,{backgroundColor: style1.profile_bg !== null ? style1.profile_bg : 'rgb(53, 56, 63)'}]}>
        <img style={Styles.image} src={style.icon}></img>
      </View>
      <View style={{ flexGrow: 1, paddingTop: 15, flex: 1 }}>
        <CustomText style={ChatBotName}>{who}</CustomText>
        <View style={BotStyles.container}>
          <View style={[MessageContainerStyle, style1]}>
            <View style={MessageTextStyle}>
              {isTyping && (
                <TypewriterEffect
                  text={formattedMessage}
                  onComplete={() => {
                    setIsTyping(false);
                    setIsDone(true);
                  }}
                  color={fontColor}
                />
              )}
            </View>
            {!isTyping && (
              <View style={MessageTextStyle}>
                {hasLink ? (
                  <LinkMessage></LinkMessage>
                ) : (
                  <CustomText style={{ color: fontColor }}>
                    {formattedMessage}
                  </CustomText>
                )}
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default BotMessage;
