import React from "react";
import { ProgressBarStyles } from "../../styles/progressBarStyles";
import { View } from "react-native";
import CustomText from "./CustomText";

const Progress_bar = ({ progress, showProgress }) => {
  const roundedNumber = Math.round(progress);
  return (
    <View
      style={[
        {
          display: !showProgress && "none",
        },
        ProgressBarStyles.parentDiv,
      ]}
    >
      <View style={ProgressBarStyles.innerDiv}>
        <View style={ProgressBarStyles.progrss_bar_container}>
          <View style={ProgressBarStyles.progress_bar_outer_div}>
            <View
              style={[
                ProgressBarStyles.progress_bar,
                { width: `${roundedNumber}%` },
              ]}
            ></View>
          </View>
        </View>
        <View style={{ flex: 1, paddingVertical: 10 }}>
          <View style={{ display: "-webkit-box" }}>
            <CustomText
              style={{
                overflow: "hidden",
                color: "#FFF",
              }}
            >
              {`${roundedNumber}%`}
            </CustomText>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Progress_bar;
