import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";

const GlobalStateContext = createContext();

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within GlobalStateProvider");
  }
  return context;
};


export const GlobalStateProvider = ({ children }) => {
  const [timeStampArray, setTimeStampArray] = useState([]);
  const addTimeStamp = (timeStamp,ago) => {
    setTimeStampArray((prevTimestamps) => [
      ...prevTimestamps,
      {timeStamp,ago },
    ]);
    return new Promise((resolve, reject) => {
      // Assuming some asynchronous operation inside addTimeStamp
      // For example:
      setTimeout(() => {
        const timeStamp = moment.utc(timeStamp).format(); // Format as needed
        resolve(timeStamp);
      }, 1000); // Simulating an asynchronous operation with a setTimeout
    });
  };
  const updateTimestamps = () => {
    // Update relative time values in the array
    const timeNow = new Date();
    const currentTime = moment(timeNow).format("YYYY-MM-DDTHH:mm:ssZ");
    const updatedTimestamps = timeStampArray.map((stamp) => {
      const ago = moment(stamp.timeStamp).from(currentTime);

      return {
        ...stamp,
        ago: ago,
      };
    });

    // Update the state
    setTimeStampArray(updatedTimestamps);
  };

  const values = {
    timeStampArray,
    addTimeStamp,
    updateTimestamps,
  };
  return (
    <GlobalStateContext.Provider value={values}>
      {children}
    </GlobalStateContext.Provider>
  );
};
