import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import config from "../config";
import BotStyles, { Typograpy } from "../styles/BotChatStyles";
import CustomText from "./UI/CustomText";

const TypewriterEffect = ({ text, onComplete, color }) => {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadingCycles, setLoadingCycles] = useState(0);

  useEffect(() => {
    const typewriterInterval = setInterval(() => {
      if (loadingCycles < 2) {
        // Display loading message "..." twice
        setDisplayText((prevText) => (prevText === "..." ? "" : prevText + "."));
      } else {
        // Type the actual message
        const currentChar = text[currentIndex];
        setDisplayText((prevText) => (currentChar === '\n' ? prevText + '\n' : prevText + currentChar));
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }

      if (loadingCycles < 2 && displayText.length === 3) {
        // Increment loading cycles when "..." is complete
        setLoadingCycles((prevCycles) => prevCycles + 1);
      }

      if (loadingCycles === 2 && currentIndex === text.length) {
        // Stop the typewriter effect after typing the actual message
        clearInterval(typewriterInterval);
        onComplete();
      }
    }, loadingCycles < 2 ? config.loadingTime : config.typeSpeed);

    return () => clearInterval(typewriterInterval);
  }, [text, currentIndex, onComplete, loadingCycles, displayText]);

  return (
    <View style={BotStyles.botText}>
      <CustomText style={{color:color}}>{displayText}</CustomText>
    </View>
  );
};

export default TypewriterEffect;
