export const ProgressBarStyles = {
  parentDiv: {
    height: 100,
    width: "100%",
    position: "fixed",
    bottom: 0,
    borderTop: "1px solid rgba(255, 255, 255, 0.10)",
    left: 0,
    backgroundColor: "rgb(30, 33, 39)",
  },
  innerDiv: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    paddingVertical: 5,
    flexDirection: "row",
    width: "30%",
    gap: 20,
    alignItems: "center",
    marginHorizontal: "auto",
  },
  progrss_bar_container: {
    flex: 4,
    display: "flex",
    paddingVertical: 10,
  },
  progress_bar_outer_div: {
    height: 4,
    width: "100%",
    position: "relative",
    borderRadius: "10px",
    background: "#66686D",
  },
  progress_bar: {
    height: "100%",
    position: "absolute",
    borderRadius: 10,
    backgroundColor: "#32D583",
    flex: "1 0 0",
  },
};
