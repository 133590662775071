import React from 'react'
import { StyleSheet, TextInput } from 'react-native'
import {Styles} from '../../styles/UserChatStyles';

function CustomInput(props) {
    const combinedStyles = StyleSheet.compose(Styles.inputText,props.style);
  return (
   <TextInput {...props} style={combinedStyles}></TextInput>
  )
}


export default CustomInput