import React from 'react';
import {Text as RNText, StyleSheet } from 'react-native';
import Styles from '../../styles/MainChatStyles';

const CustomText = (props) => {
  const combinedStyles = StyleSheet.compose(Styles.customFont, props.style);
  return <RNText {...props} style={combinedStyles} />;
};



export default CustomText;
