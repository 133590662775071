export const ModelStyles = {
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    lineSpacing: 2,
    marginBottom:100,
  },
  rightClick: {
    width: 100,
    height: 50,
  },
  textFirst: {
    fontSize: "1rem",
    textAlign: "center",
  },
  secondText:{
    color:'gray',
    marginTop:20,
    fontSize:'0.9rem',
    textAlign:'center',
  }
};

