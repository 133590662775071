import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { ModelStyles } from "../../styles/FinishModelStyle";
import RightClick from "../../assets/rightTick.svg";
import CustomText from "./CustomText";
function FinishInterviewModel({ count }) {
  const [isInterview, setIsInterview] = useState(false);
  useEffect(() => {
    if (count === 3) {
      setIsInterview(true);
    }
  }, []);
  return (
    <View style={ModelStyles.container}>
      {isInterview && (
        <View style={{ display: "flex", gap: 24 }}>
          <View
            style={{
              alignContent: "center",
              margin: "auto",
            }}
          >
            <img style={ModelStyles.rightClick} src={RightClick}></img>
          </View>

          <CustomText style={ModelStyles.textFirst}>
            {`You did it! You made it through our first interview, congrats 🎉`}
          </CustomText>
        </View>
      )}
      <View>
        <CustomText style={ModelStyles.secondText}>
          {`You may close this window now.`}
        </CustomText>
      </View>
    </View>
  );
}

export default FinishInterviewModel;
