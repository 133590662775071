import { useEffect, useRef, useState } from "react";
import React from "react";
import { Image, Platform, Text, View } from "react-native";
import Loading from "../assets/Spinner2.svg";
import logo from "../assets/vira-logo.png";
import axios from "axios";
import BotMessage from "./UI/BotMessage";
import UserFormMessage from "./UI/UserFormMessage";
import { FlatList, ScrollView } from "react-native";
import Styles, { LoadingStyles } from "../styles/MainChatStyles";
import config from "../config";
import { useLocation } from "react-router-dom";
import Progress_bar from "./UI/ProgressBar";
import Header from "./UI/Header";
import FinishInterviewModel from "./UI/FinishInterviewModel";
import CustomText from "./UI/CustomText";
import { useGlobalState } from "./GlobalStateProvider";

const MainChat = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [baseUrl, setBaseUrl] = useState(config.api);
  const [order, setOrder] = useState(0);
  const [nextOrder, setNextOrder] = useState(0);
  const [isFirstIndex, setIsFirstIndex] = useState(true);
  const [messages, setMessages] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isWhiteLabel, setIsWhiteLabel] = useState(false);
  const [showFinishModel, setShowFinishModel] = useState(false);
  const scrollViewRef = useRef(null);
  const [history, setHistory] = useState([]);
  const pathSegment = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const segmentLength = pathSegment.length;
  const [reqUrl, setReqUrl] = useState("");
  const [isEnd, setIsEnd] = useState(false);
  const messageRef = useRef(messages);
  const { addTimeStamp, timeStampArray, updateTimestamps } = useGlobalState();

  const scrollToBottom = () => {
    if (Platform.OS === "web") {
      // Web platform
      window.scrollTo(
        0,
        document.body.scrollHeight || document.documentElement.scrollHeight
      );
    } else {
      // React Native platform
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollToEnd({ animated: true });
      }
    }
  };
  const getDataOnSubmit = (status) => {
    if (status) {
      setIsFirstIndex(false);
      //if (order === 0 && isFirstIndex) {
      setOrder(nextOrder);
      // }
    }
  };

  const [styles, setStyles] = useState({
    header_bg_color: "",
    bot_bg_color: "",
    logo_url: "",
    candidate_bg_color: "",
    font_color: "",
  });
  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  useEffect(() => {
    if (isFirstIndex) {
      getCompanyDetails();
    }
    if (segmentLength < 1) {
      setReqUrl(`${baseUrl}/chit-chats/${order}`);
    }
    if (segmentLength == 1) {
      setReqUrl(`${baseUrl}/chit-chats/${pathSegment[0]}/${order}`);
    }
    if (segmentLength == 3) {
      setReqUrl(
        `${baseUrl}/questions/${pathSegment[0]}/${pathSegment[1]}/${pathSegment[2]}/${order}`
      );
    }
    if (segmentLength == 2) {
      setReqUrl(
        `${baseUrl}/chit-chats/${pathSegment[0]}/${pathSegment[1]}/${order}`
      );
    }
  }, [location.pathname, order]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(reqUrl);
        const data = await response.data;
        if (data) {
          setMessageToObj(data);
        }
      } catch (error) {
        if (error.response) {
          const data = error.response.data;
          setMessageToObj(data);
        }
        console.error("Error fetching data:", error);
      }
    };
    if (order === 0 && !isFirstIndex) {
      return;
    } else {
      fetchData();
    }
  }, [reqUrl]);

  useEffect(() => {
    // Update messagesRef.current when messages state changes
    messageRef.current = messages;
  }, [messages]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateTimestamps();
    }, 60000);
    return () => clearInterval(intervalId);
  }, [updateTimestamps]);

  useEffect(() => {
    history.forEach((msg) => {
      const time_stamp = msg?.timestamp ? msg.timestamp : "";
      addTimeStamp(time_stamp, msg.ago);
    });
    // Add one more timestamp with an empty string for the last index after history data
    if (history.length > 0) addTimeStamp(null, null);
  }, [history]);
  function setCompanyTheme(data) {
    const {
      header_bg_rgba,
      bot_bg_rgba,
      logo_url,
      candidate_bg_rgba,
      profile_bg_color,
      font_rgba,
    } = data?.company || data;
    const whiteLabel = data?.company.white_label || false;

    setIsWhiteLabel(whiteLabel);
    const logoUrl = decodeURIComponent(logo_url.replace(/\\(.)/g, "$1"));

    let company_icon =
      data.company.icon_url ||
      (data.company.icon_url !== null &&
        data.company.icon_url !== "" &&
        data.company.icon_url !== undefined)
        ? data.company.icon_url
        : logoUrl;
    setStyles({
      header_bg_color: header_bg_rgba,
      bot_bg_color: bot_bg_rgba,
      logo_url: logoUrl || "",
      profile_bg_color: profile_bg_color,
      candidate_bg_color: candidate_bg_rgba,
      font_color: font_rgba,
      icon: company_icon,
    });
  }

  async function getCompanyDetails() {
    if (pathSegment.length === 0) {
      pathSegment[0] = "vira";
    }
    let url = `/companies/${pathSegment[0]}`;
    if (pathSegment.length > 1)
      url = `/positions/${pathSegment[0]}/${pathSegment[1]}`;
    try {
      const response = await axios.get(`${baseUrl}${url}`);
      const data = await response.data;
      setCompanyName(data.company.company);
      setPosition(data.position);
      if (data) {
        setCompanyTheme(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  function setMessageToObj(data) {
    if (data.company) {
      setCompanyTheme(data);
    }
    const newMessages = [];
    if (data.chit_chat) {
      const newMessage = {
        who: data?.who ? data.who : config.vira,
        messageType: "normal",
        message: data.chit_chat,
        isCompleted: false,
      };
      newMessages.push(newMessage);
    } else if (data.history) {
      if (data.progress) {
        setProgress(data.progress);
        setShowProgress(true);
      }
      setIsFirstIndex(false);
      const historyMsg = data.history;
      if (historyMsg.length > 0) {
        setHistory(historyMsg);
        //already filled form  history
        historyMsg.map((msg, index) => {
          let agoTime = msg.ai
            ? ""
            : timeStampArray.length > 0
            ? timeStampArray[index]
            : msg.ago;

          const newMessage = {
            who: msg.who,
            ai: msg.ai,
            messageType: "normal",
            message: msg.message,
            isCompleted: true,
            ago: agoTime,
          };
          newMessages.push(newMessage);
        });

        // last message of the history
        const newMessage = {
          who: data.who,
          ai: data.ai,
          messageType: "normal",
          message: data.message,
          isCompleted: false,
        };
        newMessages.push(newMessage);
      } else {
        // messages from the bot
        const newMessage = {
          who: data.who ? data.who : config.vira,
          ai: data.ai,
          messageType: "normal",
          message: data.message,
          isCompleted: data.ai ? false : true,
          ago: data?.ago,
        };
        newMessages.push(newMessage);
        addTimeStamp(null, null);
      }
    }
    // form for the candidate
    if (Object.keys(data.form).length !== 0) {
      const { form } = data;
      const newMessage = {
        who: form.who,
        messageType: form.type,
        message: form,
        isCompleted: true,
      };
      newMessages.push(newMessage);
    }

    if (data.order === 0 && !isFirstIndex) {
      setIsEnd(true);
    }
    setNextOrder(data.order + 1);
    setMessages((prev) => [...prev, ...newMessages]);
    setIsLoading(false);
  }

  const nextQuestionFetch = (data) => {
    setMessageToObj(data);
  };
  const onTypewriterComplete = (index) => {
    setMessages((prevMessages) => {
      return prevMessages.map((message, i) => {
        if (i === index) {
          return { ...message, isCompleted: true };
        }
        return message;
      });
    });
    //setIsCompleted(true);
    const isLastMsg = index === messages.length - 1;
    if (isLastMsg && !isEnd) {
      setOrder(nextOrder);
    } else if (isEnd && isLastMsg) {
      setShowFinishModel(true);
    }
  };

  const renderItem = ({ item, index }) => {
    const lastItem = index > 0 ? messages[index - 1] : item;
    const isCompletedLast = lastItem.isCompleted;
    if (item.who === config.vira || item?.ai) {
      return (
        <BotMessage
          key={index}
          style={styles}
          msg={item}
          isCompleted={item.isCompleted}
          onTypewriterComplete={onTypewriterComplete}
          index={index}
        />
      );
    } else
      return (
        <UserFormMessage
          key={index}
          index={index}
          isCompleted={isCompletedLast}
          style={styles}
          onSubmit={getDataOnSubmit}
          onSendingAnswer={nextQuestionFetch}
          msg={item}
        />
      );
  };

  if (isLoading)
    return (
      <View style={LoadingStyles.container}>
        <View style={LoadingStyles.innerContainer}>
          <Image source={Loading} alt="Loading" style={LoadingStyles.image} />
         
        </View>
      </View>
    );

  return (
    <View style={{ flex: 1, backgroundColor: "rgba(255, 99, 71, 0)" }}>
      {!isWhiteLabel && (
        <View style={Styles.powerdByContainer}>
          <View style={Styles.poweredBy}>
            <img
              style={{ width: 24, height: 24, marginTop:5}}
              src={logo}
            ></img>
            <CustomText style={{ color: "#66686D", textAlign: "center" }}>
              Powered By Vira.AI
            </CustomText>
          </View>
        </View>
      )}
      <View style={Styles.mainContainer}>
        <View style={Styles.chatContainer}>
          <Header styles={styles} company={companyName} position={position} />
          <ScrollView
            style={Styles.scrollViewContent}
            ref={scrollViewRef}
            onContentSizeChange={scrollToBottom}
          >
            <FlatList style={{marginBottom:60}}
              data={messages}
              stickyHeaderIndices={[0]}
              renderItem={renderItem}
              keyExtractor={(item, index) => index.toString()}
            />
            {showFinishModel && <FinishInterviewModel count={segmentLength} />}
          </ScrollView>

          {showProgress && (
            <Progress_bar progress={progress} showProgress={showProgress} />
          )}
        </View>
      </View>
    </View>
  );
};

export default MainChat;
