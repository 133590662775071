import { Text, View } from "react-native";
import Styles from "../../styles/MainChatStyles";
import CustomText from "./CustomText";
import moment from "moment";
import { useGlobalState } from "../GlobalStateProvider";

const UserMessage = ({ color, msg,index }) => {
  const {timeStampArray} = useGlobalState();
const utcTime = timeStampArray[index]?.timeStamp;
const agoTime = utcTime && moment.utc(utcTime).fromNow();
  const fontColor= color;
  return (
    <View style={{ width: "100%",display:'flex'}}>
        <CustomText
          style={{
            color: fontColor,
          }}
        >
          {msg}
        </CustomText>  
        {agoTime && 
        <CustomText style={Styles.timeSection}>{agoTime}</CustomText> }
    </View>
  );
};

export default UserMessage;
